<template>
  <section class="about-descr">
    <div class="container--small">
      <div class="about-descr__inner">
        <p class="about-descr__text main__text">
          Фирма «Технологический Экспертный Центр» – украинская компания,
          аккредитована<br>
          Национальным агентством организация, как независимая испытательная
          лаборатория.
        </p>
        <p class="about-descr__text main__text">
          С 2019 года мы проводим работы по испытаниям и технической диагностике
          транспортных<br>
          средств на соответствие всем обязательным нормативным документам.
          Испытания проводятся<br>
          на 9 участках технической лаборатории «ТЭЦ» с помощью современного
          испытательного<br>
          оборудования.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutDescription"
};
</script>
